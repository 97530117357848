import Select from 'component/Select/select'
import ProfileButton from 'component/Sidebar/profile-button'
import { useAgency } from 'context/agency'
import { useAuth } from 'context/auth'
import HomeIcon from 'icon/home'
import LocationIcon from 'icon/locationicon'
import NewsFeedIcon from 'icon/news'
import SettingIcon from 'icon/settingicon'
import TeamIcon from 'icon/team'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Api from 'utils/api'

const menu = [
  {
    label: 'Settings',
    path: './business',
    icon: <SettingIcon />,
    key: 'business',
  },
  {
    label: 'NewsFeed',
    path: './newsfeed',
    icon: <NewsFeedIcon />,
    key: 'newsfeed',
  },
]

function Item({ icon, path, label }) {
  const [active, setActive] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    setActive(pathname == path.replace('.', ''))
  }, [path, pathname])

  return (
    <Link to={path}>
      <div
        // className='flex items-center mx-2'
        className={`flex items-center p-2 mx-4 rounded-lg 
            ${active ? 'bg-gray-100 ' : 'text-gray-500'}
            ${active ? ' text-gray-900' : 'text-gray-500'}
    
             ${'hover:bg-gray-100'} group`}
      >
        {icon}
        <span className='ml-2'>{label}</span>
      </div>
    </Link>
  )
}

function TopHeader() {
  const { businesses, selectedBusiness, handleSelectBusiness } = useAgency()

  console.log(selectedBusiness)

  return (
    <div className='bg-slate-200 p-4 flex justify-end'>
      <div className='w-3/12 mr-auto'>
        <Select
          onSelect={handleSelectBusiness}
          placeholder={'Select Business'}
          value={selectedBusiness?.directory?._id}
          options={businesses.map((business) => ({
            label: business.fullNameLowerCase,
            value: business.directory._id,
          }))}
        />
      </div>
      {menu.map((item) => (
        <Item {...item} />
      ))}
      <div className='pl-5 border-l border-black pt-1'>
        <ProfileButton />
      </div>
    </div>
  )
}

export default TopHeader
