import env from 'configuration'
import { useAgency } from 'context/agency'
import { AuthInit } from 'context/auth'
import { LocationInit } from 'context/location'
import useScript from 'hooks/script'
import { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import Routes from 'routing/routes'
import { setGHLSessionKey } from 'utils/auth-helper'

function App() {
  const routes = useRoutes(Routes())

  return (
    <div className='h-screen overflow-y-auto relative'>
      <AuthInit>
        <LocationInit>{routes}</LocationInit>
      </AuthInit>
    </div>
  )
}

export default App
