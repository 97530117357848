import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import AdminLayout from 'screens/admin/AdminLayout'
import { Navigate } from 'react-router-dom'
import { useLocationInfo } from 'context/location'

// DIRECTORIES
const Business = ComponentLoader(
  lazy(() => import('screens/admin/Directories/directories'))
)
const UpdateBusiness = ComponentLoader(
  lazy(() => import('screens/admin/Directories/update'))
)

//NEWSFEED
const NewsFeedModule = ComponentLoader(
  lazy(() => import('screens/admin/Newsfeed'))
)
const NewsFeed = ComponentLoader(
  lazy(() => import('screens/admin/Newsfeed/newsfeed'))
)

// SETTINGS
const GeneralInfo = ComponentLoader(
  lazy(() => import('screens/admin/GeneralInfo/generalinfo'))
)

const Billings = ComponentLoader(
  lazy(() => import('screens/admin/Billings/billing'))
)
const Profile = ComponentLoader(
  lazy(() => import('screens/admin/Profile/profile'))
)

const ApiKey = ComponentLoader(
  lazy(() => import('screens/admin/ApiKey/apikey'))
)
const Integration = ComponentLoader(
  lazy(() => import('screens/admin/Integration/integration'))
)
const IntegrateStripe = ComponentLoader(
  lazy(() => import('screens/public/stripe/stripe'))
)
const AgencyInfo = ComponentLoader(
  lazy(() => import('screens/admin/AgencyInfo/agencyinfo'))
)

const AdminRoutes = () => {
  const { isAccessable } = useLocationInfo()
  console.log('CAMAPIGN', isAccessable('campaign'))
  return [
    {
      path: '',
      element: <AdminLayout />,
      children: [
        {
          path: 'business',
          children: [
            // {
            //   path: '',
            //   index: true,
            //   element: <Business />,
            // },
            {
              path: '',
              index: true,
              element: <UpdateBusiness />,
            },
          ],
        },
        {
          path: 'newsfeed',
          element: <NewsFeedModule />,
          children: [
            {
              path: '',
              index: true,
              element: <NewsFeed />,
            },
            {
              path: 'update',
              element: <UpdateBusiness />,
            },
          ],
        },
        {
          path: 'integration',
          element: <IntegrateStripe />,
        },
        {
          path: 'profile',
          element: <Profile />,
        },
        {
          path: 'settings',
          children: [
            {
              path: 'generalinfo',
              element: <GeneralInfo />,
            },
            {
              path: 'billings',
              element: <Billings />,
            },
            {
              path: 'integration',
              element: <Integration />,
            },
            {
              path: 'agencyinfo',
              element: <AgencyInfo />,
            },
            {
              path: '',
              index: true,
              element: <Navigate replace to={'./generalinfo'} />,
            },
            {
              path: '*',
              element: <Navigate replace to={'./generalinfo'} />,
            },
          ],
        },

        {
          path: '',
          index: true,
          element: <Navigate replace to={'./business'} />,
        },
        {
          path: '*',
          index: true,
          element: <Navigate replace to={'./business'} />,
        },
      ],
    },
    {
      path: 'integrations',
      children: [
        {
          path: 'stripe',
          element: <IntegrateStripe />,
        },
      ],
    },
    {
      path: '',
      element: <Navigate replace to={'./'} />,
    },
    {
      path: '*',
      element: <Navigate replace to={'./'} />,
    },
  ]
}

export default AdminRoutes
