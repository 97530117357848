import { useLocationInfo } from 'context/location'

function AppLogo() {
  const { location } = useLocationInfo()

  return (
    <div className='flex items-center justify-start rtl:justify-end'>
      <div className='lg:block md:block hidden'>
        <a href='/' className='flex ms-2 md:me-24'>
          <img
            src={'https://placehold.co/400'}
            className='w-8 h-8 rounded-full bg-white object-cover me-3'
            alt='FlowBite Logo'
          />
          <span
            className='self-center w-36 text-xl truncate text-white font-semibold sm:text-2xl whitespace-nowrap'
            title={location?.name || 'Location'}
          >
            {location?.name || 'Location'}
          </span>
        </a>
      </div>
    </div>
  )
}

export default AppLogo
