import Loader from 'component/loader'
import TopHeader from 'component/TopHeader'
import { useAgency } from 'context/agency'
import React from 'react'
import { Outlet } from 'react-router-dom'

function AdminLayout() {
  const { loader } = useAgency()
  return (
    <>
      <div className='flex flex-col'>
        <TopHeader />
        {loader ? (
          <Loader />
        ) : (
          <main className='p-4'>
            <Outlet />
          </main>
        )}
      </div>
    </>
  )
}

export default AdminLayout
