import React, { useState, createContext, useContext, useEffect } from 'react'
import Api from 'utils/api'
import * as authHelper from 'utils/auth-helper'
import { useAuth } from './auth'
import Loader from 'component/loader'

const initAgencyContextPropsState = {
  agency: undefined,
  setAgency: () => {},
  getAgency: async (selected_location) => {},
  businesses: [],
  setBusinesses: () => {},
  selectedBusiness: {},
  setSelectedBusiness: () => {},
  handleSelectBusiness: () => {},
  loader: false,
}

const AgencyContext = createContext(initAgencyContextPropsState)

const useAgency = () => {
  return useContext(AgencyContext)
}

const AgencyProvider = ({ children }) => {
  const [agency, setAgency] = useState({})
  const [businesses, setBusinesses] = useState([])
  const [selectedBusiness, setSelectedBusiness] = useState({})
  const { currentUser, setCurrentUser } = useAuth()
  const [loader, setLoader] = useState(true)

  const getAgency = async () => {
    const { response } = await Api('/agency', 'get')
    if (response) setAgency(response.data)
    else setAgency()
  }

  const saveAgencyKey = (agencyKey) => {
    if (agencyKey) authHelper.setAgencyKey(agencyKey)
  }

  const getBusinesses = async () => {
    const { response } = await Api(`/directory?startAfter=0`, 'get')
    setLoader(false)
    if (response?.data?.contacts) {
      setBusinesses([...response.data?.contacts])
      if (currentUser.selectedBusiness) {
        const selectedBusiness = response.data?.contacts?.find(
          (item) => item.directory._id == currentUser.selectedBusiness
        )
        setSelectedBusiness(selectedBusiness)
      } else setSelectedBusiness(response.data?.contacts[0] || {})
    }
  }

  useEffect(() => {
    if (agency?._id) {
      saveAgencyKey(agency.api_key)
      getBusinesses()
    }
  }, [agency])

  const handleSelectBusiness = async (e) => {
    setLoader(true)
    const { value } = e.target
    const location = await businesses.find((loc) => loc.directory._id === value)
    const { response } = await Api('/user', 'put', {
      payload: { selectedBusiness: location.directory._id },
      notify: true,
    })
    setLoader(false)
    if (response) {
      setSelectedBusiness(location)
      setCurrentUser(response.data)
    }
  }

  return (
    <AgencyContext.Provider
      value={{
        agency,
        setAgency,
        getAgency,
        businesses,
        setBusinesses,
        selectedBusiness,
        setSelectedBusiness,
        handleSelectBusiness,
        loader,
      }}
    >
      {children}
    </AgencyContext.Provider>
  )
}

export { AgencyProvider, useAgency }
